import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ColumnChooserService,
  ColumnMenuService,
  FilterService,
  GridModule,
  PageService,
  ReorderService,
  ResizeService,
  SearchService,
  SortService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { BaseOverlayModule } from '../base-overlay/base-overlay.module';
import { DocumentPositionModule } from '../document-position/document-position.module';
import { NumberLinkModule } from '../number-link/number-link.module';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { CreditsComponent } from './credits.component';
import { LoadCreditsDialogComponent } from './load-credits-dialog/load-credits-dialog.component';
import { CreditsRoutingModule } from './credits-routing.module';

@NgModule({
  declarations: [CreditsComponent, LoadCreditsDialogComponent],
  imports: [
    CommonModule,
    CreditsRoutingModule,
    MatIconModule,
    MatDividerModule,
    NumberLinkModule,
    MatExpansionModule,
    GridModule,
    MatButtonModule,
    BaseOverlayModule,
    MatTableModule,
    MatTooltipModule,
    MatCardModule,
    BaseDialogModule,
    DocumentPositionModule,
    MatListModule,
    MarkerSketchModule,
  ],
  providers: [PageService, FilterService, ColumnMenuService, ResizeService, SortService, ToolbarService, ColumnChooserService, ReorderService, SearchService],
})
export class CreditsModule {}
