import { RouterModule, Routes } from '@angular/router';
import { TRANSLATION_MAP } from '../services/translation.service';
import { NgModule } from '@angular/core';
import { CREDIT_ITEMS_RESOLVE, CREDITS_RESOLVE } from '../services/credits.service';
import { CreditsComponent } from './credits.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CreditsComponent,
    data: { breadcrumb: { label: TRANSLATION_MAP.app.Credits }, cacheKey: 'credits' },
    resolve: {
      credits: CREDITS_RESOLVE,
      creditItems: CREDIT_ITEMS_RESOLVE,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreditsRoutingModule {}
