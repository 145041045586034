<portal-base-dialog
  [hideActions]="true"
  [portalIcon]="'enthus-battery_saver'"
  [header]="translationService.translations.headers.LoadCredits.toString()"
  [contentPadding]="false"
>
  <div class="base-dialog-content">
    <mat-list>
      @for (creditPosition of this.dialogData.creditPositions; track $index) {
        @if ((appService.isDesktop$ | async) === true) {
          <portal-document-position [bypassSecurityTrustHtml]="true" [position]="creditPosition"></portal-document-position>
        } @else {
          <portal-document-position-mobile [bypassSecurityTrustHtml]="true" [position]="creditPosition"></portal-document-position-mobile>
        }
      }
    </mat-list>
  </div>
</portal-base-dialog>
