import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-grids';
import { ItemModel } from '@syncfusion/ej2-navigations';
import { IGenericPosition } from '../interfaces/generic-position.interface';
import { AppService } from '../services/app.service';
import { ICredits } from '../interfaces/credits.interface';
import { DialogService } from '../services/dialog.service';
import { SettingsService } from '../services/settings.service';
import { TranslationService } from '../services/translation.service';
import { LoadCreditsDialogComponent } from './load-credits-dialog/load-credits-dialog.component';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'portal-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent {
  public credits: ICredits = this._activatedRoute.snapshot.data.credits;
  public creditItems: Partial<IGenericPosition>[] = this._activatedRoute.snapshot.data.creditItems;
  public defaultMinWidth = 150;
  public filterSettings: FilterSettingsModel = { type: 'Menu' };
  public pageSettings: PageSettingsModel = { pageSize: 50, pageSizes: [20, 50, 100, 200] };
  public toolbar: (string | ItemModel)[] = [
    'Search',
    { align: 'Left', text: this.translationService.translations.credits.CreditsHistory.toString(), cssClass: 'custom-ejs-toolbar-header' },
  ];
  public collapsed = false;
  @ViewChild(GridComponent) grid: GridComponent;

  constructor(
    private _activatedRoute: ActivatedRoute,
    public translationService: TranslationService,
    public settingsService: SettingsService,
    public appService: AppService,
    private _dialogService: DialogService
  ) {}

  public openLoadCreditsDialog(): void {
    this._dialogService.openDialog(LoadCreditsDialogComponent, {
      hasBackdrop: true,
      data: { creditPositions: this.creditItems },
    });
  }
}
