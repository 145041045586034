import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, mergeMap, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IGenericPosition } from '../interfaces/generic-position.interface';
import { ICreditItem, ICredits } from '../interfaces/credits.interface';
import { GenericPositionService } from './generic-position.service';
import { ResolveFn } from '@angular/router';

export const CREDITS_RESOLVE: ResolveFn<ICredits> = (): Observable<ICredits> => {
  return inject(CreditsService)
    .getCredits()
    .pipe(
      catchError(() => EMPTY),
      mergeMap((credits) => {
        if (credits) {
          return of(credits);
        } else {
          return EMPTY;
        }
      })
    );
};

export const CREDIT_ITEMS_RESOLVE: ResolveFn<Partial<IGenericPosition>[]> = (): Observable<Partial<IGenericPosition>[]> => {
  return inject(CreditsService)
    .getCreditItems()
    .pipe(
      catchError(() => EMPTY),
      mergeMap((creditItems) => {
        if (creditItems) {
          return of(creditItems);
        } else {
          return EMPTY;
        }
      })
    );
};

@Injectable({
  providedIn: 'root',
})
export class CreditsService {
  constructor(
    private _httpClient: HttpClient,
    private _genericPositionService: GenericPositionService
  ) {}

  public getCredits(): Observable<ICredits> {
    return this._httpClient.get<ICredits>('api/credits').pipe(catchError((error) => throwError(() => error)));
  }

  public getCreditItems(): Observable<Partial<IGenericPosition>[]> {
    return this._httpClient.get<ICreditItem[]>('api/credits/items').pipe(
      map((creditItems: ICreditItem[]) => {
        const creditPositions: Partial<IGenericPosition>[] = [];

        creditItems.forEach((creditItem: ICreditItem) => {
          creditPositions.push(this._genericPositionService.mapPositionToGenericPosition(creditItem));
        });

        return creditPositions;
      }),
      catchError((error) => throwError(() => error))
    );
  }
}
