import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IGenericPosition } from '../../interfaces/generic-position.interface';
import { AppService } from '../../services/app.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-load-credits-dialog',
  templateUrl: './load-credits-dialog.component.html',
  styleUrls: ['./load-credits-dialog.component.scss'],
})
export class LoadCreditsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { creditPositions: Partial<IGenericPosition>[] },
    public translationService: TranslationService,
    public appService: AppService
  ) {}
}
